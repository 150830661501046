
import { defineComponent, ref } from 'vue';
import OverlayModal from '@/components/Calendar/OverlayModal.vue';
import { useI18n } from 'vue-i18n';
import Calendar from 'primevue/calendar';

export default defineComponent({
    components: {
        OverlayModal,
        Calendar
    },
    emits: ['onSubmit'],
    async setup(_, { emit }) {
        const isOpen = ref(false);

        const open = () => (isOpen.value = true);
        const close = () => (isOpen.value = false);
        const { t } = useI18n();
        const issueDate = ref<Date>(new Date());

        const onSubmitClick = () => {
            emit('onSubmit', issueDate.value);
        }

        return {
            isOpen,
            open,
            close,
            issueDate,
            onSubmitClick
        };
    }
});
