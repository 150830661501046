
import { defineComponent, ref, reactive } from 'vue';
import OverlayModal from '../Calendar/OverlayModal.vue';
import { OrdersFilter } from '@/models/Order';
import { OrderStatus, ArrivalConfirmation } from '@/models/Enums';
import { DateRange, IntIdName } from '@/models/Interfaces';
import { Product } from '@/models/Product';
import { api } from '@/services/Api';
import useProduct from '@/modules/useProduct';
import { generalStore } from '@/store';
import { useI18n } from 'vue-i18n';
import swal from 'sweetalert2';
import Multiselect from '@vueform/multiselect';
import Calendar from 'primevue/calendar';
import CheckBoxInput from '../CheckBoxInput.vue';
import useOrder from '@/modules/useOrder';

export default defineComponent({
    components: { OverlayModal, Multiselect, Calendar, CheckBoxInput },
    name: 'OrderFilterModal',
    async setup(props: any, { emit }) {
        const isOpen = ref(false);
        const filter = ref(new OrdersFilter());
        const orderStatus = ref<IntIdName[]>([]);
        const products = ref<Product[]>([]);
        const channels = ref();
        const priceTypes = ref();
        const { dateRangeValidate } = useProduct();
        const { convertDate } = useOrder();
        const reservationDateRange = reactive(new DateRange());
        const arrivalDateRange = reactive(new DateRange());
        const arrivalTypes = ref<IntIdName[]>([]);
        const { t } = useI18n();
        const maxWidth = window.innerWidth;

        function capitalizeFirstLetter(text: string) {
            return text.charAt(0).toLowerCase() + text.slice(1);
        }
        async function open(filterParam: OrdersFilter) {
            swal.showLoading();
            filter.value = filterParam;
            if (orderStatus.value.length == 0) {
                for (const [propertyKey, propertyValue] of Object.entries(OrderStatus)) {
                    if (!Number.isNaN(Number(propertyKey))) continue;
                    orderStatus.value.push({ id: Number(propertyValue), name: propertyKey });
                }
            }
            if (arrivalTypes.value.length == 0) {
                for (const [propertyKey, propertyValue] of Object.entries(ArrivalConfirmation)) {
                    if (!Number.isNaN(Number(propertyKey))) continue;
                    if (propertyValue == 0) continue;
                    arrivalTypes.value.push({
                        id: Number(propertyValue),
                        name: t(`order.arrivalConfirmation.${capitalizeFirstLetter(propertyKey)}`)
                    });
                }
            }

            priceTypes.value = await generalStore.dispatch('getPriceTypes');
            channels.value = await generalStore.dispatch('getChannels');
            const apiResult = await api.getProducts({ productName: '', typeId: '', status: null, keyword: '' });
            products.value = apiResult.data?.products.sort((a, b) => (a.active === b.active ? 0 : a.active ? -1 : 1)) || [];
            if (filter.value?.startDate) {
                reservationDateRange.fromDate = new Date(filter.value?.startDate);
            }
            if (filter.value?.endDate) {
                reservationDateRange.toDate = new Date(filter.value?.endDate);
            }
            if (filter.value?.arrivalStartDate) {
                arrivalDateRange.fromDate = new Date(filter.value?.arrivalStartDate);
            }
            if (filter.value?.arrivalEndDate) {
                arrivalDateRange.toDate = new Date(filter.value?.arrivalEndDate);
            }
            isOpen.value = true;
            swal.close();
        }
        function Close() {
            isOpen.value = false;
        }
        function Search() {
            if (filter.value) {
                if (reservationDateRange.fromDate) {
                    filter.value.startDate = convertDate(reservationDateRange.fromDate);
                }
                if (reservationDateRange.toDate) {
                    filter.value.endDate = convertDate(reservationDateRange.toDate);
                }
                if (arrivalDateRange.fromDate) {
                    filter.value.arrivalStartDate = convertDate(arrivalDateRange.fromDate);
                }
                if (arrivalDateRange.toDate) {
                    filter.value.arrivalEndDate = convertDate(arrivalDateRange.toDate);
                }
                filter.value.pageIndex = 0;
            }

            generalStore.commit('saveOrdersFilter', filter.value);
            emit('search', filter.value);
            Close();
        }
        function ResetFilter() {
            emit('search', new OrdersFilter());
            Close();
        }
        const dateChanged = (dateRange: DateRange) => {
            if (!dateRange.fromDate || !dateRange.toDate) return;
            dateRangeValidate(dateRange);
        };
        return {
            open,
            Search,
            Close,
            ResetFilter,
            dateChanged,
            isOpen,
            filter,
            orderStatus,
            products,
            reservationDateRange,
            arrivalDateRange,
            priceTypes,
            channels,
            arrivalTypes,
            maxWidth
        };
    }
});
