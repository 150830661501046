
import { defineComponent, computed, ref, onMounted, inject } from 'vue';
import router from '@/router';
import { api } from '@/services/Api';
import Q from 'q';
import { generalStore } from '@/store';
import { useI18n } from 'vue-i18n';
import swal from 'sweetalert2';
import { OrdersFilter, MyOrder, RevenueByCurrency, MyOrderCustomer } from '@/models/Order';
import useOrder from '@/modules/useOrder';
import { onActivated, onDeactivated } from 'vue';
import { useRoute } from 'vue-router';
import { ArrivalConfirmation, ExportOrdersType, OrderStatus, OrderStatusColors } from '@/models/Enums';
import OrderArrivalConfirmationActions from '@/components/Order/OrderArrivalConfirmationActions.vue';
import { ApiResult } from '@/services/BaseApi';
import { AwaitTaskResponse, AwaitBulkInvoicesResponse } from '@/models/Interfaces';
import OrderQtyReportModal from '@/components/Order/OrderQtyReportModal.vue';
import OrderFilterModal from '@/components/Order/OrderFilterModal.vue';
import { MessageTemplateResource, SendEmailSmsAction, toResource } from '@/models/MessageTemplate';
import SaveSendNotificationSmsEmailTemplate from '@/components/Modals/SaveSendNotificationSmsEmailTemplate.vue';
import ExportOrdersModal from '@/components/Modals/ExportOrdersModal.vue';
import BulkInvoiceCreateModal from '@/components/Modals/BulkInvoiceCreateModal.vue';
import moment from 'moment';
import { LocalStorageKeys } from '@/models/LocalStorageKeys';

export default defineComponent({
    components: {
        OrderArrivalConfirmationActions,
        OrderQtyReportModal,
        SaveSendNotificationSmsEmailTemplate,
        ExportOrdersModal,
        BulkInvoiceCreateModal,
        OrderFilterModal
    },
    name: 'Orders',
    data() {
        return {
            doSearch: false
        };
    },
    async beforeRouteEnter(to, from, next) {
        if (from.name == 'order' && from.params.id) {
            to.params.updateOrderId = from.params.id;
        }
        next();
    },
    async beforeRouteLeave() {
        const grid = document.getElementById('desktop-grid');
        if (grid) {
            localStorage.setItem(LocalStorageKeys.ORDER_SCROLL_POSITION, grid.scrollTop.toString());
        }
        return true;
    },
    async setup() {
        const { t } = useI18n();
        const mobileShow = ref(false);
        const desktopShow = ref(false);
        const modalSaveSendNotificationSmsEmailTemplate = ref<InstanceType<typeof SaveSendNotificationSmsEmailTemplate>>();
        const modalQtyReport = ref<InstanceType<typeof OrderQtyReportModal>>();
        const orderFilterModal = ref<InstanceType<typeof OrderFilterModal>>();
        const exportOrdersModal = ref<InstanceType<typeof ExportOrdersModal>>();
        const route = useRoute();
        const filter = ref<OrdersFilter>(new OrdersFilter());
        filter.value = generalStore.getters.getOrderFilter;
        if (filter.value) {
            filter.value.pageIndex = 0;
        }
        const orderNotificationsUnreadMessages = computed<number>(() => generalStore.getters.getOrderNotificationsUnreadMessages);
        const orders = ref<MyOrder[]>([]);
        const busy = ref(false);
        const hasNext = ref(false);
        const total = ref(0);
        const totalFiltered = ref(0);
        const revenueByCurrencies = ref<RevenueByCurrency[]>([]);
        const { convertDate, getMinutes, removemillis } = useOrder();
        const getCurrencySymbol = (id: string) => generalStore.getters.getCurrencySymbol(id);
        const bulkInvoiceCreateModal = ref<InstanceType<typeof BulkInvoiceCreateModal>>();

        const hasFilter = computed<boolean>(() => {
            if (filter.value.status) {
                return true;
            }
            if (filter.value.productId) {
                return true;
            }
            if (filter.value.orderNumber) {
                return true;
            }
            if (filter.value.startDate) {
                return true;
            }
            if (filter.value.priceTypeId) {
                return true;
            }
            if (filter.value.arrivalStartDate) {
                return true;
            }
            if (filter.value.arrivalEndDate) {
                return true;
            }
            if (filter.value.channelId) {
                return true;
            }
            return false;
        });

        const createNewOrder = () => {
            generalStore.commit('clearOrder');
            router.push({ name: 'neworder' });
        };

        async function ValidateFilterData(filter: OrdersFilter) {
            if (!filter.orderNumber) filter.orderNumber = null;
            if (!filter.status) filter.status = null;
            if (!filter.startDate) filter.startDate = null;
            if (!filter.endDate) filter.endDate = null;
            if (!filter.arrivalStartDate) filter.arrivalStartDate = null;
            if (!filter.arrivalEndDate) filter.arrivalEndDate = null;
            if (!filter.channelId) filter.channelId = '';
        }

        async function OpenSendSmsEmailModal() {
            modalSaveSendNotificationSmsEmailTemplate.value?.open();
        }

        async function CreateBulkInvoices() {
            bulkInvoiceCreateModal.value?.open();
        }

        async function CreateBulkInvoicesOnSubmit(issueDate: Date) {
            const swalResult = await swal.fire({
                icon: 'info',
                title: t('alert.create-bulk-invoices-title'),
                customClass: {
                    confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
                    cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                },
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: t('alert.yes'),
                cancelButtonText: t('alert.no')
            });
            if (!swalResult.isConfirmed) {
                return;
            }

            const issueDateFormatted = moment(issueDate).format('YYYY-MM-DD');
            swal.fire({ allowEscapeKey: false, allowOutsideClick: false });
            swal.showLoading();
            const apiResult = await api.CreateBulkInvoices(filter.value, issueDateFormatted);
            if (apiResult.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.errorMessage
                });
                return;
            }
            generalStore.dispatch('StartCreateBulkInvoicesTask', apiResult.data!.taskId);
            swal.close();
            bulkInvoiceCreateModal.value?.close();
        }

        async function SendSmsEmail(req: SendEmailSmsAction) {
            const template = req.template;
            swal.showLoading();
            const filterData: OrdersFilter = JSON.parse(JSON.stringify(filter.value));
            ValidateFilterData(filterData);
            const templateResources: MessageTemplateResource = toResource(template);
            const apiPromise = api.SendSmsEmailByOrderFilter(filterData, templateResources, false);
            await Q.delay(400);
            const apiResult = await apiPromise;
            if (apiResult.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.errorMessage
                });
                return;
            }
            let msg = '';
            let totalCount = 0;
            if (template.sendSms) {
                const smsCount = apiResult.data?.smsCount ?? 0;
                totalCount += smsCount;

                msg += '<p class="mb-8">' + t('modal.sendmessage.smscount', { count: smsCount }) + '</p>';
            }
            if (template.sendEmail) {
                const emailCount = apiResult.data?.emailCount ?? 0;
                totalCount += emailCount;

                msg += '<p class="mb-8">' + t('modal.sendmessage.emailcount', { count: emailCount }) + '</p>';
            }
            if (totalCount == 0) {
                swal.fire({
                    icon: 'info',
                    text: 'No receipts'
                });
                return;
            }
            const swalResult = await swal.fire({
                icon: 'info',
                title: t('modal.sendmessage.continue') + '?',
                html: msg,
                customClass: {
                    confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
                    cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                },
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: t('button.send'),
                cancelButtonText: t('send-sms-email.cancel')
            });
            await Q.delay(400);
            if (swalResult.isConfirmed) {
                swal.showLoading();
                await Q.delay(200);
                const apiPromise2 = api.SendSmsEmailByOrderFilter(filterData, templateResources, true);
                await Q.delay(400);
                const apiResult2 = await apiPromise2;
                if (apiResult2.errorMessage) {
                    swal.fire({
                        icon: 'error',
                        text: apiResult.errorMessage
                    });
                    return;
                }
                swal.fire({
                    icon: 'success',
                    text: t('modal.sendmessage.msgsent')
                });
                modalSaveSendNotificationSmsEmailTemplate.value?.close();
            }
        }

        async function qtyReport() {
            swal.showLoading();
            const filterData: OrdersFilter = JSON.parse(JSON.stringify(filter.value));
            ValidateFilterData(filterData);
            const apiPromise = api.qtyReportGet(filterData);
            await Q.delay(400);
            const apiResult = await apiPromise;
            if (apiResult.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.errorMessage
                });
                return;
            }
            if (apiResult.data) {
                modalQtyReport.value?.open(apiResult.data.customers);
            }
            swal.close();
        }

        const ShowFilter = () => {
            generalStore.commit('saveOrdersFilter', filter.value);
            orderFilterModal.value?.open(filter.value);
        };

        function goToDetails(orderId: string) {
            router.push({ name: 'order', params: { id: orderId } });
        }

        const getMyOrders = async () => {
            swal.showLoading();
            busy.value = true;
            const filterData: OrdersFilter = JSON.parse(JSON.stringify(filter.value));
            ValidateFilterData(filterData);
            const apiResult = await api.getMyOrders(filterData);
            await Q.delay(400);
            if (apiResult.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.errorMessage
                });
                return;
            }
            hasNext.value = apiResult.data?.hasNext || false;
            if (apiResult.data && filterData.pageIndex == 0) {
                total.value = apiResult.data.total;
                totalFiltered.value = apiResult.data.totalFiltered;
                revenueByCurrencies.value = apiResult.data.revenueByCurrencies;
            }
            if (apiResult.data && orders.value && orders.value.length) {
                orders.value = [...orders.value, ...apiResult.data.rows];
            } else {
                orders.value = apiResult.data?.rows || [];
            }
            filter.value.pageIndex++;
            busy.value = false;
            swal.close();
        };

        async function getOrders() {
            orders.value = [];
            filter.value.pageIndex = 0;
            await getMyOrders();
        }

        function ListenerGetMyOrders() {
            if (hasNext.value && !busy.value) {
                getMyOrders();
            }
        }

        const onMountedCall = async () => {
            route.params.newSearch = '1';
        };

        async function GetRQOrders() {
            filter.value.unreadMessages = false;
            filter.value.startDate = '';
            filter.value.endDate = '';
            filter.value.status = 2;
            filter.value.pageIndex = 0;
            generalStore.commit('saveOrdersFilter', filter.value);
            orders.value = [];
            await getMyOrders();
        }

        async function searchOrder(newFilter: OrdersFilter) {
            filter.value = newFilter;
            orders.value = [];
            await getMyOrders();
        }

        async function getUnreadMessagesOrders() {
            filter.value.unreadMessages = true;
            filter.value.startDate = '';
            filter.value.endDate = '';
            filter.value.status = 0;
            filter.value.pageIndex = 0;
            generalStore.commit('saveOrdersFilter', filter.value);
            orders.value = [];
            await getMyOrders();
        }

        async function OrdersForToday() {
            filter.value.unreadMessages = false;
            filter.value.status = null;
            filter.value.pageIndex = 0;
            const data = new Date(Date.now());
            filter.value.arrivalStartDate = convertDate(data);
            filter.value.arrivalEndDate = convertDate(data);
            generalStore.commit('saveOrdersFilter', filter.value);
            orders.value = [];
            await getMyOrders();
        }

        const emitter: any = inject('emitter');

        function handleScroll() {
            const app = document.getElementById('desktop-grid');
            if (app) {
                if (app.scrollTop + app.clientHeight >= app.scrollHeight) {
                    ListenerGetMyOrders();
                }
            }
        }
        function settingTableType() {
            const appElement = document.getElementById('app');
            if (appElement) {
                const offsetWidth = appElement.offsetWidth;
                if (offsetWidth > 991) {
                    desktopShow.value = true;
                } else {
                    mobileShow.value = true;
                }
            }
        }

        function settingScroll() {
            if (desktopShow.value) {
                const desktopGrid = document.getElementById('desktop-grid');
                if (desktopGrid) {
                    desktopGrid.addEventListener('scroll', handleScroll);
                }
            }
            if (mobileShow.value) {
                emitter.on('isBottom', ListenerGetMyOrders);
            }
        }

        async function updateOrderRow(orderId: string) {
            const order = orders.value.find(x => x.id == orderId);
            if (order) {
                const filter = new OrdersFilter();
                filter.orderNumber = order.orderNumber.toString();
                const apiResault = await api.getMyOrders(filter);
                if (apiResault.errorMessage) {
                    return;
                }
                if (apiResault.data && apiResault.data.rows.length == 1) {
                    const newOrder = apiResault.data.rows[0];
                    order.customerFullName = newOrder.customerFullName;
                    order.productName = newOrder.productName;
                    order.customerMobile = newOrder.customerMobile;
                    order.orderProductStartDate = newOrder.orderProductStartDate;
                    order.slotStartTime = newOrder.slotStartTime;
                    order.customers = newOrder.customers;
                    order.total = newOrder.total;
                    order.balance = newOrder.balance;
                    order.invoiceTotal = newOrder.invoiceTotal;
                    order.receiptTotal = newOrder.receiptTotal;
                    order.arrivalConfirmationId = newOrder.arrivalConfirmationId;
                    order.haveRemarks = newOrder.haveRemarks;
                    order.orderStatusId = newOrder.orderStatusId;
                    order.orderStatusName = newOrder.orderStatusName;
                    order.haveNewMessages = newOrder.haveNewMessages;
                }
            }
        }

        async function onActivatedAction() {
            settingTableType();
            filter.value = generalStore.getters.getOrderFilter;
            if (route.params.newSearch) {
                filter.value.pageIndex = 0;
                orders.value = [];
                await getMyOrders();
            } else {
                swal.showLoading();
                const scrollPosition = localStorage.getItem(LocalStorageKeys.ORDER_SCROLL_POSITION);
                const grid = document.getElementById('desktop-grid');
                if (scrollPosition && grid) {
                    grid.scrollTop = Number(scrollPosition);
                }
                if (route.params.updateOrderId) {
                    await updateOrderRow(route.params.updateOrderId.toString());
                }
                swal.close();
            }

            const apiOrderNotifications = await api.getOrdersNotifications();
            if (apiOrderNotifications.error || apiOrderNotifications.errorMessage) {
                swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: apiOrderNotifications.errorMessage });
                return;
            }
            generalStore.commit('setOrdersNotifications', apiOrderNotifications.data);
            settingScroll();
        }

        async function onDeactivatedAction() {
            emitter.off('isBottom', ListenerGetMyOrders);
            const app = document.getElementById('desktop-grid');
            if (app) {
                app.removeEventListener('scroll', handleScroll);
            }
        }

        async function showAll() {
            filter.value = new OrdersFilter();
            orders.value = [];
            await getMyOrders();
        }

        async function sotrBy(sotrBy: string) {
            if (filter.value.orderBy != sotrBy) {
                filter.value.asc = true;
            } else {
                filter.value.asc = !filter.value.asc;
            }
            filter.value.orderBy = sotrBy;
            await getOrders();
        }

        function getArrowClass(sort: string) {
            if (sort == filter.value.orderBy) {
                if (filter.value.asc) {
                    return 'top-sort-arrow';
                } else {
                    return 'bottom-sort-arrow';
                }
            }
            return 'standart-sort-arrow';
        }

        function showWarning(index: number) {
            document.getElementById('order-remark-' + index)?.classList.remove('dp-none');
            document.getElementById('order-remark-' + index)?.classList.add('dp-block');
        }
        function hideWarning(index: number) {
            document.getElementById('order-remark-' + index)?.classList.remove('dp-block');
            document.getElementById('order-remark-' + index)?.classList.add('dp-none');
        }
        function showCustomerTypeHelpTextModal(index: number) {
            document.getElementById('customer-type-' + index)?.classList.remove('dp-none');
            document.getElementById('customer-type-' + index)?.classList.add('dp-block');
        }
        function hideCustomerTypeHelpTextModal(index: number) {
            document.getElementById('customer-type-' + index)?.classList.remove('dp-block');
            document.getElementById('customer-type-' + index)?.classList.add('dp-none');
        }
        onMounted(onMountedCall);
        onDeactivated(onDeactivatedAction);
        onActivated(onActivatedAction);

        const isArrivalConfirmationExpand = ref(false);
        const isArrivalConfirmationExpandMobile = ref(false);
        const orderIdForArrivalConfirmation = ref(0);

        function arrivalConfirmation(orderId: number) {
            isArrivalConfirmationExpand.value = true;
            orderIdForArrivalConfirmation.value = orderId;
        }

        function arrivalConfirmationMobile(orderId: number) {
            isArrivalConfirmationExpandMobile.value = true;
            orderIdForArrivalConfirmation.value = orderId;
        }

        async function onSaveArrivalConfirmation(event: { value: number; id: string }) {
            isArrivalConfirmationExpand.value = false;
            isArrivalConfirmationExpandMobile.value = false;
            swal.showLoading();
            const response = await api.UpdateArrivalConfirmation({ orderId: event.id, arrivalConfirmation: event.value });
            if (response.errorMessage) {
                swal.fire({ icon: 'error', text: response.errorMessage });
                return;
            }
            const order = orders.value.find(x => x.id === event.id);
            if (order) {
                order.arrivalConfirmationId = event.value;
            }
            swal.close();
        }

        const getHoursMinutes = (totalMinutes: number): string => {
            const minutes = (totalMinutes % 60).toString();
            const hours = Math.floor(totalMinutes / 60).toString();
            if (hours == '0') {
                return t('orders.duration-minutes', { minutes: minutes });
            }
            if (minutes == '0') {
                return t('orders.duration-hours', { hours: hours });
            }
            return t('orders.duration-hours-minutes', { hours: hours, minutes: minutes });
        };

        const showExportPopup = async () => exportOrdersModal.value?.open();

        function displayCustomers(customers: MyOrderCustomer[]) {
            let result = '';
            let index = 0;
            customers.forEach(customer => {
                if (index != 0) {
                    result += ', ';
                }
                result += customer.qty + ' ' + customer.typeName;
                index++;
            });
            return result;
        }

        async function awaitTask(action: () => Promise<ApiResult<AwaitTaskResponse>>, onSuccess: (url: string) => void, waitTime = 3000) {
            swal.fire(t('swalAction.processing'));
            swal.showLoading();
            await Q.delay(waitTime);

            const resp = await action();
            if (resp.errorMessage) {
                await swal.fire({
                    icon: 'error',
                    text: resp.errorMessage
                });
                return;
            }
            if (resp.data?.url) {
                onSuccess(resp.data.url);
                return;
            }

            if (waitTime > 0) {
                awaitTask(action, onSuccess, waitTime);
            }
        }

        const exportOrders = async (type: any) => {
            swal.showLoading();
            const filterData: OrdersFilter = JSON.parse(JSON.stringify(filter.value));
            const apiResult = await api.exportOrders(filterData, type.exportType);
            await Q.delay(400);
            if (apiResult.data?.error || !apiResult.data) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.data?.error || 'No data'
                });
                return;
            }
            const taskId = apiResult.data.taskId;
            awaitTask(
                () => api.waitExportTask(taskId),
                url => {
                    swal.fire({
                        icon: 'success',
                        title: t('alert.exportReady.orders'),
                        html: `
                    <div class="text-center my-4">
                        <a href="${url}" target="_blank">
                            <button role="button" class="popup-bookit-button my-1 px-4">${t('report.download')}</button>
                        </a>
                    </div>`,
                        showConfirmButton: false,
                        showCloseButton: true
                    });
                }
            );
        };

        return {
            getUnreadMessagesOrders,
            createNewOrder,
            ShowFilter,
            hasFilter,
            goToDetails,
            orders,
            total,
            totalFiltered,
            getOrders,
            filter,
            GetRQOrders,
            OrdersForToday,
            getMinutes,
            removemillis,
            OrderStatusColors,
            showAll,
            sotrBy,
            desktopShow,
            mobileShow,
            getArrowClass,
            revenueByCurrencies,
            getCurrencySymbol,
            orderNotificationsUnreadMessages,
            isArrivalConfirmationExpand,
            isArrivalConfirmationExpandMobile,
            arrivalConfirmation,
            arrivalConfirmationMobile,
            onSaveArrivalConfirmation,
            orderIdForArrivalConfirmation,
            showExportPopup,
            exportOrdersModal,
            exportOrders,
            showWarning,
            hideWarning,
            getHoursMinutes,
            qtyReport,
            SendSmsEmail,
            OpenSendSmsEmailModal,
            CreateBulkInvoices,
            modalQtyReport,
            displayCustomers,
            showCustomerTypeHelpTextModal,
            hideCustomerTypeHelpTextModal,
            modalSaveSendNotificationSmsEmailTemplate,
            bulkInvoiceCreateModal,
            CreateBulkInvoicesOnSubmit,
            orderFilterModal,
            searchOrder
        };
    }
});
