
import { defineComponent, ref } from 'vue';
import OverlayModal from '@/components/Calendar/OverlayModal.vue';
import { ExportOrdersType } from '@/models/Enums';

export default defineComponent({
    components: {
        OverlayModal
    },
    emits: ['exportOrders'],
    async setup(_, { emit }) {
        const isOpen = ref(false);

        const open = () => (isOpen.value = true);
        const back = () => (isOpen.value = false);

        function exportOrders(type: ExportOrdersType) {
            emit('exportOrders', { exportType: type });
        }

        return {
            isOpen,
            open,
            back,
            ExportOrdersType,
            exportOrders
        };
    }
});
